import styled from "styled-components"

export const ProfilePictureContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const ValidationReasonChip = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: center;
  font-size: 9px;
  border-radius: 4px;
  margin-bottom: 5px;
  color: white;
  background-color: ${props => props.backgroundColor};
`
