import { gql } from "@apollo/client"

export const getKeeper = gql`
  query adminGetKeeper($id: String!) {
    adminGetKeeper(id: $id) {
      keeper {
        id
        email
        phone
        firstName
        lastName
        createdAt
        status
        address {
          id
          streetLine
          zipcode
          city
          status
          housingType
          digicode
          building
          floor
          instructions
          maxParcels
        }
        nbTotalDeliveredParcels
        anonymized
        anonymizationDate
      }
      documents {
        id
        type
        url
        status
      }
      wallet {
        id
        bankAccount {
          isStripeValidated
          updatedAt
        }
        balanceDetails {
          nbParcels
          processing
          amount
          type
          date
          associationName
          entity
          parcels {
            id
            parcelNo
          }
        }
        balance
      }
      availabilities {
        id
        date
        startHour
        endHour
        recurring
      }
      carriers {
        allowed
        carrierId
        carrierName
      }
      events {
        id
        entityId
        entity
        eventCode
        eventDate
        eventReason
        title
        description
        data
      }
      keeperScoring {
        rating
        deliveryFailureRate {
          totalRate
          colissimo
          geodis
          gls
          relaisColis
          dhl
        }
        deliveryTime
        contactTime
        commitment7Days
        commitment30Days
      }
      note {
        id
        content
        zendeskLink
      }
      listAddresses {
        id
        status
      }
    }
  }
`

export const getKeepersToCheck = gql`
  query adminGetKeepersToCheck {
    adminGetKeepersToCheck {
      id
      email
      phone
      firstName
      lastName
      createdAt
      pictureUrl
      status
      address {
        id
        streetLine
        zipcode
        city
        status
        housingType
        digicode
        building
        floor
        instructions
        maxParcels
      }
      documents {
        id
        type
        url
        status
      }
      validationReason {
        reason
      }
      currentOpenAiValidationLog {
        status
        idcardStatus
        addressStatus
        explanationToUser
        explanationToAgent {
          ageOK
          fullNameID
          documentType
          expirationOK
          addressNumber
          birthdateMatch
          documentNumber
          frontPhotoSide
          photoVisibility
          documentAvailable
          nameSurnameMatch
          addressInstruction
        }
      }
    }
  }
`
