import React, { ReactElement } from "react"
import styled from "styled-components"
import { ParcelAction } from "modules/Ops/Parcel/getParcelActions"
import ParcelStatusChip from "components/Parcels/ParcelStatus"
import { Icon } from "semantic-ui-react"

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #e3faeb;
  border-radius: 20px;
  margin: 10px 0px;
  padding: 10px 20px;
`

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
  color: #838383;
`

const OutcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 12px;
  margin-left: 50px;
`

const OutcomeRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const OutcomeTitle = styled.div`
  font-size: 12px;
  font-weight: 600;
`

interface ActionOutcomeDetailsProps {
  actionOutcome: ParcelAction["options"][0]["actionOutcome"]
}

export const ActionOutcomeDetails = ({ actionOutcome }: ActionOutcomeDetailsProps): ReactElement => {
  return (
    <Container>
      <Header>
        <Icon
          name="info circle"
          style={{
            color: "white",
            height: 30,
            width: 30,
            backgroundColor: "#3ab76b",
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />

        <Title>Changements à venir</Title>
      </Header>
      <OutcomeContainer>
        <OutcomeRow>
          <OutcomeTitle style={{ marginRight: 10 }}>📦 Nouveau statut </OutcomeTitle>
          <ParcelStatusChip status={actionOutcome.nextStatus} />
        </OutcomeRow>
        <OutcomeRow>
          <OutcomeTitle style={{ marginRight: 10 }}>💰 Crédits Keeper </OutcomeTitle>
          {actionOutcome.walletEntries}
        </OutcomeRow>
        <OutcomeRow>
          <OutcomeTitle style={{ marginRight: 10 }}>💰 Facturation transporteur </OutcomeTitle>
          {actionOutcome.billingEntries}
        </OutcomeRow>
        <OutcomeRow>
          <OutcomeTitle style={{ marginRight: 10 }}>🔔 Notifications envoyées </OutcomeTitle>
          {actionOutcome.notifications}
        </OutcomeRow>
      </OutcomeContainer>
    </Container>
  )
}
