import React, { Fragment, ReactElement } from "react"
import Zoom from "react-medium-image-zoom"
import { Popup } from "semantic-ui-react"

import LineElementRow from "components/Table/LineElementRow"
import LineElementCol from "components/Table/LineElementCol"
import LineElementContainer from "components/Table/LineElementContainer"
import { TextEllipsis, TextEllipsisForLongInstructions } from "components/Parcels/LineItemParcel.styled"
import { ProfilePictureContainer, ValidationReasonChip } from "components/KeeperChecking/KeeperToCheckLine.styled"
import KeeperCheckChips from "components/KeeperChecking/KeeperCheckChips"
import KeeperProfilePicture from "components/Keeper/KeeperProfilePicture"
import { DocumentEntityType } from "types/document.types"
import { KeeperCheckResult, KeeperInChecking, KeeperValidationReason } from "types/keeper.types"
import { HousingType } from "types/address.types"
import OpenAiValidationStatusComponent from "./OpenAiValidationStatusComponent"

interface KeeperToCheckLineProps {
  keeper: KeeperInChecking
  updateKeeperCheckResult: (id: string, checkResult: KeeperCheckResult) => void
}

const KeeperToCheckLine = ({ keeper, updateKeeperCheckResult }: KeeperToCheckLineProps): ReactElement => {
  const address = keeper.address
  const IDCard = keeper?.documents.find(({ type }) => type === DocumentEntityType.IDCARD)

  const validationReasons = [
    { reason: KeeperValidationReason.NEW_KEEPER, label: "👋 Nouveau", backgroundColor: "#33CC99" },
    { reason: KeeperValidationReason.RELOCATION, label: "🏠 Déménagement", backgroundColor: "#FCBE47" },
    { reason: KeeperValidationReason.RE_VALIDATION, label: "🤷 Re-validation", backgroundColor: "#FCBE47" },
    { reason: KeeperValidationReason.UPDATE_INFORMATIONS, label: "✍️ Màj informations", backgroundColor: "#F76B73" },
  ]
  const validationReasonChip = validationReasons.find(vr => vr.reason === keeper.validationReason.reason)

  const currentOpenAiValidationLog = keeper.currentOpenAiValidationLog
  console.log(currentOpenAiValidationLog)
  return (
    <LineElementContainer lineHeight="lg" disabled={keeper.checkResult !== null}>
      <LineElementRow>
        <LineElementCol
          width={"10%"}
          size={2}
          content={
            <>
              <ValidationReasonChip backgroundColor={validationReasonChip.backgroundColor}>
                {validationReasonChip.label}
              </ValidationReasonChip>
              <ProfilePictureContainer>
                <KeeperProfilePicture url={keeper.pictureUrl} />
                {keeper.firstName} {keeper.lastName}
              </ProfilePictureContainer>
            </>
          }
        />
        <LineElementCol
          width={"5%"}
          size={1}
          content={
            <Zoom>
              <img alt="Document" src={IDCard?.url} height="45" style={{ borderRadius: 5 }} />
            </Zoom>
          }
        />
        <LineElementCol
          width={"10%"}
          size={2}
          content={
            <div>
              {address.streetLine}{" "}
              <b>
                {address.zipcode} {address.city}
              </b>
            </div>
          }
        />
        <LineElementCol
          width={"15%"}
          size={2}
          label={"Instructions"}
          content={
            <Popup
              content={
                <div>
                  {address.housingType === HousingType.HOUSE ? <span>🏘</span> : <span>🏢</span>} {address.instructions}
                </div>
              }
              size="small"
              trigger={
                address.instructions?.length > 55 ? (
                  <TextEllipsisForLongInstructions>
                    {address.housingType === HousingType.HOUSE ? <span>🏘</span> : <span>🏢</span>}{" "}
                    {address.instructions}
                  </TextEllipsisForLongInstructions>
                ) : (
                  <TextEllipsis>
                    {address.housingType === HousingType.HOUSE ? <span>🏘</span> : <span>🏢</span>}{" "}
                    {address.instructions}
                  </TextEllipsis>
                )
              }
            />
          }
        />
        <LineElementCol width={"3%"} size={1} label={"Étage"} content={<div>{address.floor || "-"}</div>} />
        <LineElementCol
          width={"5%"}
          size={1}
          label={"Bâtiment"}
          content={
            <Popup
              content={<div>{address.building}</div>}
              size="small"
              trigger={<TextEllipsis>{address.building || "-"}</TextEllipsis>}
            />
          }
        />
        <LineElementCol
          width={"5%"}
          size={1}
          label={"Digicode"}
          content={
            <Popup
              content={<div>{address.digicode}</div>}
              size="small"
              trigger={<TextEllipsis>{address.digicode || "-"}</TextEllipsis>}
            />
          }
        />
        <LineElementCol
          width={"7%"}
          size={1}
          label={"Décision IA"}
          content={<OpenAiValidationStatusComponent currentOpenAiValidationLog={currentOpenAiValidationLog} />}
        />
        <LineElementCol
          width={"10%"}
          size={1}
          label={"Explication IA"}
          content={
            <Popup
              content={
                <div style={{ fontSize: 12, lineHeight: "14px" }}>{currentOpenAiValidationLog?.explanationToUser}</div>
              }
              size="large"
              trigger={<TextEllipsis>{currentOpenAiValidationLog?.explanationToUser || "-"}</TextEllipsis>}
            />
          }
        />
        <LineElementCol
          width={"20%"}
          size={3}
          content={
            <KeeperCheckChips
              id={keeper.id}
              keeperCheckResult={keeper.checkResult}
              updateKeeperCheckResult={updateKeeperCheckResult}
            />
          }
        />
      </LineElementRow>
    </LineElementContainer>
  )
}

export default KeeperToCheckLine
